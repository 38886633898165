import React from 'react'

function SettingsContent() {
  return (
    <div>
    <h2 className="text-xl font-semibold mb-4">Settings</h2>
    <p>Settings content goes here...</p>
  </div>
  )
}

export default SettingsContent
