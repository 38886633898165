import React from 'react'

function ProfileContent() {
  return (
    <div>
    <h2 className="text-xl font-semibold mb-4">Profile</h2>
    <p>Profile content goes here...</p>
  </div>
  )
}

export default ProfileContent
