import React, { useEffect, useState } from 'react';
import axios from 'axios';

const MessagesContent = () => {
    
  <div>
    hello messages..!!
  </div>
};

export default MessagesContent;
